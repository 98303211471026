/**
 * getQuixTypeByRole
 */
function getQuixTypeByRole (userRole) {
  switch (userRole) {
    case "USERROLE11115":
    case "USERROLE11116":
      return {
        "QUIZ": "quiz"
      }
    default:
      return {
        "QUIZ": "quiz",
        "COMPETITION": "competition",
        "GAME_HANGMAN": "game_hangman",
        "COUNSELLING": "counselling",
        "TEST_PREP": "testseries"
      }
  }
}
export default getQuixTypeByRole
