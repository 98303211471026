/*eslint-disable */
import request from '../../Utils/curl'

let TPrepSubjectModules = {
  /**
   * tprepSubjectModuleList
   */
  async tprepSubjectModuleList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "tprep_subject_module_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at tprepSubjectModuleList() and Exception:',err.message)
    }
  },

  /**
   * tprepSubjectModuleReportList
   */
  async tprepSubjectModuleReportList (context, whereFilter=null) {
    try{
      let post_data = new FormData();
      if (whereFilter.gide_user_id) {
        post_data.append('gide_user_id', whereFilter.gide_user_id)
      }

      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "tprep_subject_module_report_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at tprepSubjectModuleReportList() and Exception:',err.message)
    }
  },

  /**
   * tprepSubjectModuleCount
   */
  async tprepSubjectModuleCount (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter.filter) {
        post_data.append('filter', JSON.stringify(whereFilter.filter))
      }

      if (whereFilter.gide_user_id) {
        post_data.append('gide_user_id', whereFilter.gide_user_id)
      }

      return await request.curl(context, "tprep_subject_module_count", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at tprepSubjectModuleList() and Exception:',err.message)
    }
  },

  /**
   * tprepSubjectModuleView
   */
  async tprepSubjectModuleView (context, subjectModuleId) {
    try {      
      let post_data = new FormData();
      post_data.append('subject_module_id', subjectModuleId);
      return await request.curl(context, "subject_module_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at tprepSubjectModuleView() and Exception:",err.message)
    }    
  },

  /**
   * tprepSubjectModuleAdd
   */
  async tprepSubjectModuleAdd (context, subjectModuleObj) {
    try{
      let post_data = new FormData();
    
      for (let key in subjectModuleObj) {
        post_data.append(key, subjectModuleObj[key]);
      }

      return await request.curl(context, "tprep_subject_module_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at tprepSubjectModuleAdd() and Exception:',err.message)
    }
  },

  /**
   * tprepSubjectModuleEdit
   */
  async tprepSubjectModuleEdit (context, subjectModuleObj) {
    try{
      let post_data = new FormData();
    
      for (let key in subjectModuleObj) {
        post_data.append(key, subjectModuleObj[key]);
      }

      return await request.curl(context, "tprep_subject_module_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at tprepSubjectModuleEdit() and Exception:',err.message)
    }
  },

  /**
   * tprepSubjectModuleDelete
   */
  async tprepSubjectModuleDelete (context, subjectModuleId) {
    try{
      let post_data = new FormData();
      
      post_data.append('subject_module_id', subjectModuleId);

      return await request.curl(context, "tprep_subject_module_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at tprepSubjectModuleDelete() and Exception:',err.message)
    }
  }
}

export {
  TPrepSubjectModules
}
