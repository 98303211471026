/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" :key="update+'global'">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <!-- form-row -->
              <div class="form-row">
                <!-- App Type -->
                <div class="col-md-12 mb-3">
                  <label for="app_type">{{ cvExamTypeLabel }}</label>
                  <ValidationProvider name="Quiz Type" rules="required" v-slot="{ errors }">
                    <select :disabled="isAppTypeDisabled" id="app_type" class="form-control" v-model="vmQuizFormData.app_type" @change="update += 1">
                      <option v-for="(Exam_type, index) of QuizType" :key="(index+1)" :value="Exam_type" >
                        {{index}}
                      </option>
                    </select>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- App Type -->

                <!-- Test Prep Subject DropDown -->
                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == QuizType.TEST_PREP">
                  <label for="subject_name">Subject</label>
                  <ValidationProvider name="Subject Name" rules="required" v-slot="{ errors }">
                    <select id="subject_name" class="form-control" v-model="vmQuizFormData.subject_name" @change="update += 1">
                      <option v-for="(subject, index) of subjects" :key="(index+1)" :value="subject" >
                        {{subject.interest_name}}
                      </option>
                    </select>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Test Prep Subject DropDown -->

                <!-- Test Prep Subject Module DropDown -->
                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == QuizType.TEST_PREP">
                  <label for="subject_module_name">Subject Module</label>
                  <ValidationProvider name="Subject Module Name" rules="required" v-slot="{ errors }">
                    <select id="subject_module_name" class="form-control" v-model="vmQuizFormData.subject_module_name" @change="update += 1">
                      <option v-for="(Subject_Module, index) of subjectModules" :key="(index+1)" :value="Subject_Module" >
                        {{Subject_Module.subject_module_name}}
                      </option>
                    </select>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Test Prep Subject Module DropDown -->

                <!-- App Name -->
                <div class="col-md-12 mb-3">
                  <label for="app_name">{{ cvExamNameLabel }}</label>
                  <ValidationProvider name="Quiz Name" rules="required" v-slot="{ errors }">
                    <input id="app_name" v-model="vmQuizFormData.app_name" type="text" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- App Name -->

                <!-- Upload Image -->
                <div class="col-md-12">
                  <label>{{ cvPBACoverPic }} : {{ imageSize }}</label>
                  <div class="form-group row align-items-center ml-1">
                    <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                      <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                      <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                      <label class="p-image">
                        <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                        <input class="file-upload" type="file" required accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                      </label>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                </div><!-- Upload Image -->

                <!-- App/Quiz Description -->
                <div class="col-md-12 mb-3">
                  <label for="validationexam_description">
                    {{ cvExamDescriptionLabel }}
                  </label>
                    <ValidationProvider   name="Quiz Description"
                      rules="required"
                      v-slot="{ errors }"
                    >
                    <vue-editor v-model="vmQuizFormData.app_description"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- App/Quiz Description -->

                <!-- App Category -->
                <div class="col-md-12 mb-3">
                  <label for="validationexam_category">{{ cvExamCategoryLabel }}</label>
                  <select v-model="vmQuizFormData.exam_category_option" class="form-control">
                    <option disabled value="">Please select one</option>
                    <option v-for="item in vmqbkExamCategoryList" :key="item.app_category">{{ item.app_category }}</option>
                    <option value="AddNew">Add New</option>
                  </select>
                  <div v-if="vmQuizFormData.exam_category_option ==='AddNew' " style="margin-top: 5px" >
                    <input v-model="vmQuizFormData.app_category" placeholder="Enter the question Exam category" type="text" class="form-control" required />
                  </div>
                </div><!-- App Category -->

                <!-- App Category -->
                <div class="col-md-12 mb-3">
                  <label for="app_is_gideplus">{{ cvExamIsGidePlusLabel }}</label>
                  <ValidationProvider name="Is this a GidePlus Quiz ?" rules="required" v-slot="{ errors }">
                    <multiselect
                      id="app_is_gideplus"
                      v-model="app_is_gideplus"
                      :options="cvExamIsGidePlusOptions"
                      track-by="name"
                      label="name">
                    </multiselect>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- App Category -->

                <!-- App Competition Date Time -->
                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == QuizType.COMPETITION">
                  <label for="validationexam_date">{{ cvExamDateLabel }}</label>
                  <ValidationProvider name="Quiz Date" v-slot="{ errors }">
                    <datetime
                      type="datetime"
                      v-model="vmQuizFormData.exam_date"
                      input-class="gide_datetime_input"
                      placeholder="Date month year at time"
                      class="theme-primary"
                      :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'}"
                      :phrases="{ok: 'Continue', cancel: 'Exit'}"
                      :hour-step="1"
                      :minute-step="5"
                      :week-start="7"
                      use12-hour
                      auto
                      :min-datetime="vmStartDate ? vmStartDate : minStartDate"
                      required>
                    </datetime>
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- App Competition Date Time -->

                <!-- Quiz Duration -->
                <div class="col-md-12 mb-3">
                  <label for="app_duration">{{ cvExamDurationLabel }}</label>
                  <ValidationProvider name="Quiz Duration (in seconds)" rules="required" v-slot="{ errors }">
                    <input id="app_duration" v-model="vmQuizFormData.app_duration" placeholder="60" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Quiz Duration -->

                <!-- Quiz Total Questions -->
                <div class="col-md-12 mb-3">
                  <label for="app_total_questions">{{ cvExamTotalQuestion }}</label>
                  <ValidationProvider name="Set Total Questions in this Quiz" rules="required" v-slot="{ errors }">
                    <input id="app_total_questions" v-model="vmQuizFormData.app_total_questions" placeholder="1" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Quiz Total Questions -->

                <!-- Batch No -->
                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == 'testseries'">
                  <label for="validationexam_date">
                    {{ cvQuestionBatchNo }}</label>
                    <ValidationProvider
                    name="Set The batch No. The Question Belongs To"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.batch_no" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div><!-- Batch No -->

                <!-- Quiz Total Score -->
                <div class="col-md-12 mb-3">
                  <label for="validationexam_totalScore">{{ cvExamTotalScore }}</label>
                  <ValidationProvider name="Set Quiz Total Score" rules="required" v-slot="{ errors }">
                    <input id="validationexam_totalScore" v-model="vmQuizFormData.app_total_score" placeholder="100" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Quiz Total Score -->

                <!-- Quiz Passing Score -->
                <div class="col-md-12 mb-3">
                  <label for="app_passing_score">{{ cvExamPassingScore }}</label>
                  <ValidationProvider name="Set Quiz Passing Score" rules="required" v-slot="{ errors }">
                    <input id="app_passing_score" v-model="vmQuizFormData.app_passing_score" placeholder="50" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Quiz Passing Score -->

                <!-- Is the Quiz Paid -->
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3 gide_inline_textorcheckbox" v-if="vmQuizFormData.app_type == QuizType.COMPETITION">
                  <label class="lable" for="app_is_paid">{{ cvExamIsPaid }}</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="app_is_paid" v-model="vmQuizFormData.app_is_paid" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div><!-- Is the Quiz Paid -->

                <!-- Are questions Shuffled or Ordered -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="is_ques_shuffled" v-model="vmQuizFormData.is_ques_shuffled" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="is_ques_shuffled">{{ cvShuffle }}</label>
                </div><!-- Are questions Shuffled or Ordered -->

                <!-- Quiz has timer -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="has_timer" v-model="vmQuizFormData.has_timer" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="has_timer">{{ cvConfigureTimer }}</label>
                </div><!-- Quiz has timer -->

                <!-- Show Leaderboard -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <!-- Show Leaderboard -->
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_leaderboard" v-model="vmQuizFormData.show_leaderboard" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_leaderboard">{{ cvShowLeaderboard }}</label>
                </div><!-- Show Leaderboard -->

                <!-- Show Play Again -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_playagain" v-model="vmQuizFormData.show_playagain" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_playagain">{{ cvShowPlayAgain }}</label>
                </div><!-- Show Play Again -->

                <!-- Show rank -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_rank" v-model="vmQuizFormData.show_rank" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_rank">{{ cvShowRank }}</label>
                </div><!-- Show rank -->

                <!-- Show Analysis -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_analysis" v-model="vmQuizFormData.show_analysis" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_analysis">{{ cvShowAnalysis }}</label>
                </div><!-- Show Analysis -->

                <!-- Show Results -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_results" v-model="vmQuizFormData.show_results" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_results">{{ cvShowResults}}</label>
                </div><!-- Show Results -->

                <!-- Redirect to thank you page -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="redirect_to_thankyou_page" v-model="vmQuizFormData.redirect_to_thankyou_page" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="redirect_to_thankyou_page">{{ cvRedirectToThankyouPage}}</label>
                </div><!-- Redirect to thank you page -->

                <!-- Is One time Quiz -->
                <!--
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 gide_inline_textorcheckbox">
                  <label class="lable" for="validationexam_name">
                    {{ cvExamIsOneTime }}</label>
                    <ValidationProvider name="Is this a One-Time Quiz ?"
                    rules="required"
                    v-slot="{ errors }">
                  <input v-model="vmQuizFormData.app_is_one_time" type="checkbox" class="form-control" required />
                  <span class="text-danger"> {{ errors[0] }} </span>
                   </ValidationProvider>
                </div> -->
              </div><!-- form-row -->

              <div class="row spinner_add">
                <div class="col-7">
                  <button type="button" class="btn btn-primary" @click="appAdd()">{{ cvSubmitBtn }}</button>
                </div>
                <div class="col-5">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                </div>
              </div>
            </form><!-- form action -->
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style>
.gide_datetime_input {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 45px;
  cursor: pointer;
  padding-left: 10px;
  color: #a09e9e;
  width: 100%;
}
.theme-primary .vdatetime-popup__header,
.theme-primary .vdatetime-calendar__month__day--selected > span > span,
.theme-primary .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #000000;
}

.theme-primary .vdatetime-year-picker__item--selected,
.theme-primary .vdatetime-time-picker__item--selected,
.theme-primary .vdatetime-popup__actions__button {
  color: #000000;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<script>
import { Apps } from "../../../FackApi/api/apps.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { VueEditor } from "vue2-editor"
import "vue-datetime/dist/vue-datetime.css"
import { Datetime } from "vue-datetime"
import QuizType from "../../../FackApi/json/QuizType.js"
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"
import CropImage from "../../../components/cropImage.vue"
import { Interests } from "../../../FackApi/api/interest.js"
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "AppAdd",
  components: {
    VueEditor,
    Datetime,
    ValidationProvider,
    Multiselect,
    CropImage
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "app_add",
      cvCardTitle: "Add Application",
      cvCardSubHeader: "Add Application",
      cvSubmitBtn: "Add",
      cvExamNameLabel: "App Name",
      cvExamTypeLabel: "App Type",
      cvExamDescriptionLabel: "App Description",
      cvExamCategoryLabel: "App Category",
      cvExamIsGidePlusLabel: "App Is Gideplus ?",
      cvExamDateLabel: "App Date",
      cvExamDurationLabel: "App Duration(in second)",
      cvExamTotalQuestion: "Total Questions of This App",
      cvExamTotalScore: "App Total Score (100, 50 ,20)",
      cvExamPassingScore: "App Passing Score",
      cvShuffle: "Show questions shuffled ?",
      cvShowAnalysis: "Show Analysis",
      cvConfigureTimer: "Show timer for this Quiz ?",
      cvShowLeaderboard: "Show Leaderboard",
      cvShowPlayAgain: "Show Play Again Button",
      cvShowRank: "Show Rank",
      cvShowResults: "Show Results",
      cvRedirectToThankyouPage: "Redirect to Thank You Page",
      cvExamIsPaid: "App Is Paid ?",
      // cvExamIsOneTime: "Quiz Is One Time ?",
      cvExamImage: "App image",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "App",
      vmQuizFormData: Object.assign({}, this.initFormData()),
      vmQuizDesc: null,
      vmQuizType: null,
      subjects: [],
      subjectModules: [],
      datetimeEmpty: "",
      vmStartDate: null,
      minStartDate: null,
      app_is_gideplus: false,
      QuizType: QuizType,
      cvExamIsGidePlusOptions: [
        {
          name: "For All"
        },
        {
          name: "Gide+"
        }
      ],
      vmqbkExamCategoryList: " ",
      cvPBACoverPic: "Does this App have a Picture ? If yes then upload ",
      propCropDialogSize: "md",
      propCropImageDialogOpen: false,
      vmCoverPic: "",
      propStencilSize: null,
      fileSrc: "",
      vmPBACoverPicImage: Object.assign({}, this.initImageData()),
      isPhotoUploaded: false,
      imageMaxSize: " Maximum Size 4MB",
      imageSize: "Size  1080 * 566",
      update: 0,
      isAppTypeDisabled: false,
      cvQuestionBatchNo: "Batch No. the question belongs to"
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.QuizType = QuizType(this.userData.user_role)
    this.appCategoryDistinct()

    if (this.$route.path.split("/").includes("tprep_quiz_dashboard")) {
      this.vmQuizFormData.app_type = this.QuizType.TEST_PREP
      this.isAppTypeDisabled = true
    }
  },
  watch: {
    /**
     * On Subject Name change
     */
    "vmQuizFormData.subject_name" () {
      this.getSubjectModules(this.vmQuizFormData.subject_name)
    },
    /**
     * On app type change
     */
    "vmQuizFormData.app_type" (newAppType, oldAppType) {
      if (newAppType == this.QuizType.TEST_PREP) {
        this.getSubjects()
      }
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "app_name": "",
        "app_description": "",
        "app_category": "",
        "app_date_ts": "",
        "app_total_questions": "",
        "app_total_score": "",
        "app_passing_score": "",
        "app_duration": 60,
        "app_type": QuizType.QUIZ,
        "subject_name": "",
        "subject_module_name": "",
        "is_ques_shuffled": 0,
        "show_analysis": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmQuizFormData) {
          if (!this.vmQuizFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        question_name: "",
        module_id: ""
      }
    },
    /**
     * getSubjectModules
     */
    async getSubjectModules (subject) {
      const payload = { interest_id: subject.interest_id }
      const subjectModulesList = await TPrepSubjectModules.tprepSubjectModuleList(this, payload)
      if (subjectModulesList && subjectModulesList.resp_status) {
        this.subjectModules = subjectModulesList.resp_data.data
      }
    },
    /**
     * getSubjects
     */
    async getSubjects () {
      const interestObj = {}
      const resp = await Interests.interestWithCategoryList(this, interestObj)
      this.subjects = resp.resp_data.INTERESTCAT11117
    },
    /**
     * appCategoryDistinct
     */
    async appCategoryDistinct () {
      try {
        let qbkExamCategoryResp = await Apps.appCategoryDistinct(this)
        if (qbkExamCategoryResp && qbkExamCategoryResp.resp_status) {
          this.vmqbkExamCategoryList = qbkExamCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at appCategoryDistinct() and Exception:", err.message)
      }
    },
    /**
     * appAdd
     */
    async appAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true

        this.vmQuizFormData.app_date_ts = this.vmQuizFormData.exam_date ? Math.floor(new Date(this.vmQuizFormData.exam_date).getTime() / 1000) : null
        this.vmQuizFormData.app_is_gideplus = this.app_is_gideplus.name === "Gide+" ? 1 : 0
        this.vmQuizFormData.app_is_one_time = this.vmQuizFormData.app_is_one_time === true ? 1 : 0
        this.vmQuizFormData.app_is_paid = this.vmQuizFormData.app_is_paid === true ? 1 : 0

        if (this.vmQuizFormData.exam_category_option !== "AddNew") {
          this.vmQuizFormData.app_category = this.vmQuizFormData.exam_category_option
        }

        if (this.vmQuizFormData.app_type == this.QuizType.TEST_PREP) {
          // This will contain the name of the topic under the subject
          this.vmQuizFormData.app_category = this.vmQuizFormData.subject_module_name.subject_module_name
          this.vmQuizFormData.subject_module_id = this.vmQuizFormData.subject_module_name.subject_module_id
        }

        let quizAddResp = await Apps.appAdd(this, this.vmQuizFormData)
        ApiResponse.responseMessageDisplay(this, quizAddResp)
        if (quizAddResp && !quizAddResp.resp_status) {
          return false
        }

        if (this.isPhotoUploaded) {
          let imageAddResp = await this.imageAdd(quizAddResp.resp_data.app_id)
          if (imageAddResp && imageAddResp.resp_status) {
            quizAddResp.resp_data.app_image = imageAddResp.resp_data[0].image
          }
          this.isPhotoUploaded = false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          // when the data moves first time from add it gives app_date_ts in string that gives invalid date error
          quizAddResp.resp_data.app_date_ts = parseInt(quizAddResp.resp_data.app_date_ts)
          this.$emit("emitCloseQuizAddModal", quizAddResp.resp_data)
        }

        if (quizAddResp.resp_status) {
          let path = ""
          if (quizAddResp.resp_data.app_type === QuizType.COMPETITION) {
            // Goto evet edit page to edit the event and to let the user know that a competition related event has been created
            path = "/event_edit/" + quizAddResp.resp_data.app_id
          }
          else {
            // Goto the Quizbank page for thr user to uplaod questions
            path = "/quizBank_add/" + quizAddResp.resp_data.app_id
          }
          this.$router.push(path)
        }

        this.vmQuizFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at appAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.isPhotoUploaded = true
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024 // 4mb
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 1080,
          "height": 566
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": orgImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },

    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (appId) {
      if (this.cvImageError) {
        return
      }

      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "APPS",
          "module_id": appId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Apps.imageAddToApp(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }

        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    }
  }
}
</script>
